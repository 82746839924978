import React from "react";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import {
  CLOSE,
  PROFILE_ICON,
} from "../../../../component/MaterialIcons/constants";
import styles from "./Getfunded.module.scss";
import { toast } from "react-toastify";
import request from "../../../../utils/request";
import { get } from "lodash";
import { API_URL, USER_API, SPOTIFY_USER_API } from "../../constants";
import StorageService from "../../../../utils/StorageService";
import { GetErrorMessage } from "../../helper";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import Image from "../../../../component/Image";

export const getSpotifyData = (data) => data.items || (data.id ? [data] : []);
export const NoOptionsMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={styles.noOption}>No data to show</div>
    )
  );
};
export function onSearchTypeChange() {
  this.state.searchType === "name"
    ? this.setState({ searchType: "id" })
    : this.setState({ searchType: "name" });
}
export function handleRemoveArtist(clearValue) {
  StorageService.delete("spotify_id");
  StorageService.delete("spotify_label");
  StorageService.delete("spotify_url");
  this.setState({ id: "", name: "", image: "", selectOptions: [] });
  document.querySelector("#artistSelect .select__input").style.display =
    "inline-block";

  document.getElementById("single_value").style.display = "none";
  clearValue();
}

export function handleRemoveSearchArtist(clearValue) {
  this.setState({ id: "", name: "", image: "", selectOptions: [] });
  document.getElementsByClassName("select__input")[0].style.display =
    "inline-block";
  document.getElementById("single_value").style.display = "none";
  clearValue();
}

function getOptionResponse(res, thisref) {
  const data = getSpotifyData(res.data);
  const options = [];
  data.map((item) =>
    options.push({
      value: item.id,
      label: item.name,
      imageUrl: get(item, "images[2].url"),
    }),
  );
  thisref.setState({ selectOptions: options });
  return options;
}

export function handleChange(e) {
  if (e) {
    document.querySelector("#artistSelect .select__input").style.display =
      "none";
    this.setState({ id: e.value, name: e.label, image: e.imageUrl });
  }
}

export function getOptions(inputValue, callback) {
  if (inputValue.length < 1) {
    callback([]);
  } else {
    const reqData = {
      method: "GET",
    };
    const requestURL = `${API_URL}${USER_API}${SPOTIFY_USER_API}?q=${inputValue}&type=${this.state.searchType}&limit=40`;

    request(requestURL, reqData)
      .then((res) => {
        if (!res.status) {
          toast.error(res.message);
          callback([]);
        } else {
          const thisref = this;
          callback(getOptionResponse(res, thisref));
        }
      })
      .catch((err) => {
        callback([]);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  }
}

export const LoadingMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={styles.noOption}>
        <span className="spinner-border spinner-border-sm"></span>
      </div>
    )
  );
};

export const SingleValue = (
  { data, selectProps, ...others },
  handleRemove,
  imgURL,
  searchType,
) => {
  return (
    <div id="single_value" className={styles.selectOption}>
      <div className={`d-flex align-items-center`}>
        <div className={styles.profile}>
          {imgURL ? (
            <Image
              src={imgURL}
              alt="artist_profile"
              imageType={IMAGE_TYPE.PROFILE}
            />
          ) : (
            <SvgIcons icon={PROFILE_ICON} />
          )}
        </div>
        <div>
          <label htmlFor="identifier" className={styles.labelText}>
            {searchType === "id" && "Spotify"} Artist {getLabel(searchType)}
          </label>
          <p>{selectProps.getOptionLabel(data)} </p>
        </div>
      </div>
      <button
        type="button"
        onClick={() => handleRemove(others.clearValue)}
        onTouchEnd={() => handleRemove(others.clearValue)}
        data-testid="removeBtn"
        className={styles.removeBtn}
      >
        <SvgIcons icon={CLOSE} />
      </button>
    </div>
  );
};

export const getLabel = (val) => (val === "name" ? "Name" : "ID");
