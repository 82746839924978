import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { get, invoke } from "lodash";
import ReactTooltip from "react-tooltip";
import Recaptcha from "react-google-recaptcha";
import styles from "./SignupFastFlow.module.scss";
import { toast } from "react-toastify";
import { ReactComponent as PoweredByCC } from "../../../../assets/logos/Powered by chordCash 1.svg";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import {
  getSubDomain,
  getPartnerName,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import request from "../../../../utils/request";
import {
  ACTIVE_DISTRIBUTORS,
  API_URL,
  ARTIST_API,
  ARTIST_ESTIMATE,
  AUTH,
  CHOOSE_PATH_API,
  ERROR_PAGE,
  FUNDING,
  FUNDING_OPTION,
  LOGIN,
  PROS_API,
  PUBLISHING_REPORTS,
  SEND_REPORTS,
  SPOTIFY_USER_API,
  USER_API,
  VALIDATE_EMAIL,
  VALIDATE_PARTNER,
} from "../../constants";
import { useHistory, Link } from "react-router-dom";
import { GetErrorMessage } from "../../helper";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import { ARROW_BACK } from "../../../../component/MaterialIcons/constants";
import { Form, Formik } from "formik";
import {
  ARTIST_ENROLLMENT_INITIAL_DATA,
  BB_INITIAL_FORM,
  CC_INITIAL_FORM,
  DISPLAY_ALL_PATH_CONDITION,
  SIGNUP_FORM_FIELDS,
  SIGNUP_STEPS,
  SWITCH_DIST_OPTIONS,
} from "./constants";
import {
  EMAIL_REGEX,
  PASS_NUM_SPECIAL_REGEX,
  PASS_UPPER_LOWER_REGEX,
  PHONE_REGEX,
} from "../constants";
import {
  CHOOSE_PATH_FIELDS,
  ESTIMATES_FIELD_NAMES,
  FAST_FLOW_DATA,
  FAST_FLOW_STEPS,
  MAJOR_DISTRIBUTOR_OPTIONS,
  MAX_POLLING_TIME,
  POLLING_INTERVAL,
  RELATIONSHIP_OPTIONS,
} from "../LinkAnotherArtist/constant";
import FormField from "../../../../component/FormField/FormField";
import {
  CC_LITE_SLUGNAME,
  CHORDCASH_TERMS_OF_SERVICE,
  BEATBREAD_TERMS_OF_SERVICE,
} from "../SignUp/constant";
import { ARTISTS_RANGE } from "../../AdminDashboard/constants";
import AuthTokenService from "../../../../utils/AuthTokenService";
import Loader from "../../../../component/Loader";
import {
  ChoosePath,
  FinalHoldTightWidget,
  HoldTightWidget,
  IncomeNotVerified,
  IncomeVerified,
  LongerThanExpected,
  RequestCall,
  RequestFailedWidget,
  TooBigWidget,
  TooSmallWidget,
} from "../LinkAnotherArtist/FastFlowAckSlider";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import segment from "../../../../utils/segment";
import {
  POR_DEFAULT_OPTION,
  PRO_OTHER_OPTION,
  PUBLISHING_DEFAULT_OPTION,
  PUBLISHING_FIELDS,
  PUBLISHING_STREAMING_INCOME_MULTIPLIER,
  RIGHTS_TYPES,
  TYPE_PRO,
} from "../../YourAdvance/constants";

const SignupFastFlow = (props) => {
  const [artistData, setArtistData] = useState({});
  const [isExistingUser, setIsExistingUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoader1, setBtnLoader1] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [step, setStep] = useState("");
  const [streamingIncomeCounter, setStreamingIncomeCounter] = useState(0);
  const [otherData, setOtherData] = useState({
    streamingIncomeValue: 0,
    artistEmailId: "",
    holdTightLoading: false,
    artistId: "",
    isGeneratingOffer: false,
    rightsType: "",
    PRO: "",
    recentRevenue: 0,
  });
  const [distributorList, setDistributorList] = useState([
    ...MAJOR_DISTRIBUTOR_OPTIONS,
  ]);
  const [proOptions, setProOptions] = useState([
    POR_DEFAULT_OPTION,
    PRO_OTHER_OPTION,
  ]);
  const [publisherOptions, setPublisherOptions] = useState([
    PUBLISHING_DEFAULT_OPTION,
    PRO_OTHER_OPTION,
  ]);
  const [stepStack, setStepStack] = useState([]);
  const isBeatBread = () => BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1;
  const [estimateWidgetStep, setEstimateWidgetStep] = useState(
    isBeatBread() ? SIGNUP_STEPS.STEP1 : SIGNUP_STEPS.STEP2,
  );
  const captchaRef = useRef();
  const isGeneratingOfferRef = useRef(get(otherData, "isGeneratingOffer"));

  const themeCtx = useContext(ThemeContext);
  const history = useHistory();
  const getArtistDetails = () => {
    const data = {
      method: "GET",
    };
    const { spotifyId } = get(props, "match.params");
    const requestURL = `${API_URL}${USER_API}${SPOTIFY_USER_API}?q=${spotifyId}&type=id&limit=1`;
    request(requestURL, data)
      .then((res) => {
        if (!get(res, "data.name")) {
          invoke(history, "replace", ERROR_PAGE);
        } else {
          setArtistData(get(res, "data", {}));
        }
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  const validateAgent = () => {
    if (get(themeCtx, "slugName") === CC_LITE_SLUGNAME) {
      if (!get(props, "match.params.agentId", "")) {
        invoke(props, "history.replace", ERROR_PAGE);
        return false;
      }
      const data = {
        method: "POST",
        body: {
          affiliateId: get(props, "match.params.agentId"),
        },
      };
      const requestUrl = `${API_URL}${AUTH}${VALIDATE_PARTNER}`;
      request(requestUrl, data)
        .then((res) => {
          if (!res.status) {
            invoke(props, "history.replace", ERROR_PAGE);
          }
          return true;
        })
        .catch((err) => {
          invoke(props, "history.replace", ERROR_PAGE);
        });
    }
    return true;
  };

  useEffect(() => {
    getArtistDetails();
  }, []);

  useEffect(() => {
    if (get(themeCtx, "isPublishing")) {
      getActiveDistributors();
      fetchPROOptions();
    }
    setEstimateWidgetStep(
      get(themeCtx, "isPublishing") ? SIGNUP_STEPS.STEP1 : SIGNUP_STEPS.STEP2,
    );
  }, [get(themeCtx, "isPublishing")]);

  useEffect(() => {
    setTitle("Sign Up", themeCtx);
    validateAgent();
  }, [themeCtx]);

  const handleEmailCheck = async (form, isSubmitting = false) => {
    const err = await form.validateForm();
    if (!get(err, "email")) {
      setBtnLoader(true);
      setBtnLoader1(isSubmitting);
      setEmailLoader(true);
      const data = {
        method: "POST",
        body: {
          [ESTIMATES_FIELD_NAMES.EMAIL.NAME]: get(
            form,
            `values.${ESTIMATES_FIELD_NAMES.EMAIL.NAME}`,
            "",
          ),
        },
      };
      const requestUrl = `${API_URL}${USER_API}${VALIDATE_EMAIL}`;
      try {
        const res = await request(requestUrl, data);
        setBtnLoader(false);
        setEmailLoader(false);
        setEmailChecked(true);
        if (get(res, "status")) {
          setIsExistingUser(get(res, "data", true));
          if (get(res, "data")) {
            invoke(
              form,
              "setFieldValue",
              ESTIMATES_FIELD_NAMES.PASSWORD.NAME,
              "",
            );
          }
          return get(res, "data");
        }
        setIsExistingUser(true);
        toast.error(get(res, "message"));
        return;
      } catch (err) {
        setBtnLoader(false);
        setEmailLoader(false);
        setIsExistingUser(true);
        invoke(form, "setFieldValue", ESTIMATES_FIELD_NAMES.PASSWORD.NAME, "");
        toast.error(get(err, "message"));
        return;
      }
    }
  };

  const handleOfferGeneration = async () => {
    const { requestId } = get(props, "match.params");
    const apiData = {
      method: "GET",
    };
    let pollingCount = 0;
    const pollingTimer = setInterval(async () => {
      try {
        if (isGeneratingOfferRef.current) {
          return false;
        }
        isGeneratingOfferRef.current = true;
        setOtherData((prev) => ({ ...prev, isGeneratingOffer: true }));
        const artistResponse = await request(
          `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}?requestId=${requestId}&artistId=${get(
            otherData,
            "artistId",
          )}`,
          apiData,
        );
        isGeneratingOfferRef.current = false;
        setOtherData((prev) => ({ ...prev, isGeneratingOffer: false }));
        if (!get(artistResponse, "status")) {
          clearInterval(pollingTimer);
          setBtnLoader(false);
          setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
          setOtherData((p) => ({ ...p, holdTightLoading: false }));
          return;
        }
        if (
          get(artistResponse, "data.artistStatus") !== ARTISTS_RANGE.PENDING
        ) {
          clearInterval(pollingTimer);
          if (get(artistResponse, "data.artistAccessToken")) {
            AuthTokenService.storeToken(
              get(artistResponse, "data.artistAccessToken"),
            );
          }
          setStep(get(artistResponse, "data.artistStatus"));
          setOtherData((p) => ({ ...p, holdTightLoading: false }));
          if (
            get(artistResponse, "data.artistStatus") ===
            FAST_FLOW_STEPS.IN_RANGE
          ) {
            if (isBeatBread()) {
              setStep(FAST_FLOW_STEPS.CHOOSE_PATH);
            } else {
              invoke(history, "push", FUNDING);
            }
          }
        } else if (pollingCount >= MAX_POLLING_TIME / POLLING_INTERVAL) {
          clearInterval(pollingTimer);
          setStep(FAST_FLOW_STEPS.LONGER_THAN_EXPECTED);
          setOtherData((p) => ({ ...p, holdTightLoading: false }));
        }
        pollingCount++;
      } catch (error) {
        clearInterval(pollingTimer);
        setBtnLoader(false);
        setStep(FAST_FLOW_STEPS.ERROR);
        isGeneratingOfferRef.current = false;
        setOtherData((p) => ({ ...p, isGeneratingOffer: false }));
      }
    }, POLLING_INTERVAL);
  };

  useEffect(() => {
    if (get(otherData, "artistId")) {
      handleOfferGeneration();
    }
  }, [get(otherData, "artistId")]);

  const getActiveDistributors = () => {
    setLoading(true);
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVE_DISTRIBUTORS}`;
    request(requestUrl, data)
      .then((res) => {
        setLoading(false);
        if (get(res, "status")) {
          const options = invoke(res, "data.map", (option) => ({
            label: get(option, "name"),
            value: get(option, "name"),
          }));
          setDistributorList([...options, ...MAJOR_DISTRIBUTOR_OPTIONS]);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  const fetchPROOptions = () => {
    setLoading(true);
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${PROS_API}`;
    request(requestUrl, data)
      .then((res) => {
        setLoading(false);
        if (get(res, "status")) {
          const pro = [];
          const publisher = [];
          invoke(res, "data.map", (o) => {
            if (get(o, "type") === TYPE_PRO) {
              pro.push({
                label: get(o, "name"),
                value: get(o, "_id"),
              });
            } else {
              publisher.push({
                label: get(o, "name"),
                value: get(o, "_id"),
              });
            }
          });
          setProOptions([POR_DEFAULT_OPTION, ...pro, PRO_OTHER_OPTION]);
          setPublisherOptions([
            PUBLISHING_DEFAULT_OPTION,
            ...publisher,
            PRO_OTHER_OPTION,
          ]);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  const handleSubmitEstimates = async (formProperties, token) => {
    setBtnLoader(true);
    if (!emailChecked) {
      const check = await handleEmailCheck(formProperties, true);
      if (!check) {
        setBtnLoader1(false);
        invoke(captchaRef.current, "reset");
        return false;
      }
    }
    const phone = get(formProperties, "values.phone", "").replace(
      get(formProperties, "values.countryCode"),
      "",
    );
    const {
      email,
      name,
      amount,
      countryCode,
      password,
      relationshipWithArtist,
      majorDistributor,
      PRO,
      artistPublisher,
      recent_revenue,
      rightsType,
      switchDistributor,
    } = formProperties.values;
    const { spotifyId, requestId, agentId } = get(props, "match.params");
    const payload = {
      isFastFlow: true,
      streamingIncome: amount,
      userName: name,
      recaptchaToken: token,
      [get(themeCtx, "slugName") === CC_LITE_SLUGNAME
        ? "affiliateId"
        : "agentCode"]: agentId,
      relationshipWithArtist,
      majorDistributor,
      switchDistributor,
      spotifyId,
      requestId,
      email,
      countryCode,
      phone,
      password,
      PRO,
      artistPublisher,
      recent_revenue,
      rightsType,
    };
    const data = {
      method: "POST",
      body: payload,
    };

    const requestURL = `${API_URL}${AUTH}${ARTIST_ESTIMATE}`;
    request(requestURL, data)
      .then((res) => {
        setBtnLoader(false);
        setBtnLoader1(false);
        setOtherData((prev) => ({
          ...prev,
          rightsType,
          PRO,
          streamingIncomeValue: amount,
          artistEmailId: email,
          recentRevenue: recent_revenue,
        }));
        if (!get(res, "status")) {
          invoke(captchaRef.current, "reset");
          setLoading(false);
          toast.error(get(res, "message"));
          return;
        }
        if (!get(res, "data.isLogin")) {
          segment.track.createdAccount(true);
          segment.storage.set(
            payload.email || null,
            get(res, "data.artistId") || null,
            get(res, "data.userId") || null,
            true,
          );
          segment.identify(get(res, "data.userId"), {
            artistId: get(res, "data.artistId") || null,
          });
          setStep(FAST_FLOW_STEPS.PENDING);
          setOtherData((prev) => ({
            ...prev,
            holdTightLoading: true,
            artistId: get(res, "data.artistId"),
          }));
        } else {
          setBtnLoader(false);
          setLoading(false);
          invoke(history, "push", LOGIN);
        }
      })
      .catch((err) => {
        invoke(captchaRef.current, "reset");
        if (get(err, "response.status") === 503) {
          err.response.json().then((res) => {
            setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
            setLoading(false);
            setBtnLoader(false);
            setBtnLoader1(false);
            setOtherData((prev) => ({
              ...prev,
              serviceDownTitle: get(res, "data.widgetHeaderText"),
              serviceDownMessage: get(res, "data.widgetDetailText"),
            }));
          });
          return false;
        }
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        setBtnLoader(false);
        setStep(FAST_FLOW_STEPS.ERROR);
      });
  };

  const isBothType = (v) => RIGHTS_TYPES[2].value === v;
  const isMastersBoth = (v) =>
    [RIGHTS_TYPES[1].value, RIGHTS_TYPES[2].value].indexOf(v) !== -1;

  const validationObject = {
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, ESTIMATES_FIELD_NAMES.EMAIL.VALIDATION_MSG)
      .label("Email address"),
    name: Yup.string().required().label("Contact name"),
    amount: Yup.number()
      .required()
      .min(0, ESTIMATES_FIELD_NAMES.AMOUNT.VALIDATION_MSG_MIN)
      .typeError(ESTIMATES_FIELD_NAMES.AMOUNT.VALIDATION_MSG_TYPE)
      .label("Streaming income"),
    phone: Yup.string()
      .required()
      .matches(PHONE_REGEX, ESTIMATES_FIELD_NAMES.PHONE_NUMBER.VALIDATION_MSG)
      .label("Phone number"),
    password: !isExistingUser
      ? Yup.string()
          .required()
          .min(8, "Please enter valid password between 8 to 20 characters")
          .max(20, "Please enter valid password between 8 to 20 characters")
          .matches(
            PASS_UPPER_LOWER_REGEX,
            "Password should have at least 1 uppercase letter & 1 lowercase letter.",
          )
          .matches(
            PASS_NUM_SPECIAL_REGEX,
            "Password should have at least 1 number & 1 special character.",
          )
          .label(ESTIMATES_FIELD_NAMES.PASSWORD.LABEL)
      : Yup.string(),
    relationshipWithArtist: Yup.string().nullable().required().label("This"),
    majorDistributor: Yup.string().nullable().label("This"),
  };

  const getValidationSchemaBB = () =>
    Yup.object().shape({
      ...validationObject,
      rightsType: Yup.string().required().label("This"),
      amount: Yup.number().when(SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, {
        is: (val) =>
          [RIGHTS_TYPES[1].value, RIGHTS_TYPES[2].value].indexOf(val) !== -1,
        then: Yup.number()
          .required()
          .min(0, ESTIMATES_FIELD_NAMES.AMOUNT.VALIDATION_MSG_MIN)
          .typeError(ESTIMATES_FIELD_NAMES.AMOUNT.VALIDATION_MSG_TYPE)
          .label("Streaming income"),
      }),
      PRO: Yup.string()
        .nullable()
        .when(SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, {
          is: (val) =>
            [RIGHTS_TYPES[0].value, RIGHTS_TYPES[2].value].indexOf(val) !== -1,
          then: Yup.string().nullable().required().label("This"),
        }),
      artistPublisher: Yup.string()
        .nullable()
        .when(
          [SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, PUBLISHING_FIELDS.PRO.NAME],
          {
            is: (val, pro) =>
              pro !== POR_DEFAULT_OPTION.value &&
              [RIGHTS_TYPES[0].value, RIGHTS_TYPES[2].value].indexOf(val) !==
                -1,
            then: Yup.string().nullable().required().label("This"),
          },
        ),
      recent_revenue: Yup.number().when(
        [SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, PUBLISHING_FIELDS.PRO.NAME],
        {
          is: (val, pro) =>
            RIGHTS_TYPES[0].value === val ||
            (pro !== POR_DEFAULT_OPTION.value && val === RIGHTS_TYPES[2].value),
          then: Yup.number()
            .required("Your quarterly income be at least $1.")
            .min(0, "Your quarterly income be at least $1.")
            .label("This")
            .test({
              name: "max",
              message:
                "We can't verify this quarterly income, please enter a lower revenue figure.",
              test(v) {
                if (
                  get(this, `parent.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`) ===
                  RIGHTS_TYPES[2].value
                ) {
                  return (
                    v <=
                    get(this, `parent.${ESTIMATES_FIELD_NAMES.AMOUNT.NAME}`) *
                      PUBLISHING_STREAMING_INCOME_MULTIPLIER
                  );
                }
                return true;
              },
            }),
        },
      ),
      relationshipWithArtist: Yup.string()
        .nullable()
        .when(SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, {
          is: (val) =>
            [RIGHTS_TYPES[1].value, RIGHTS_TYPES[2].value].indexOf(val) !== -1,
          then: Yup.string().nullable().required().label("This"),
        }),
      majorDistributor: isBeatBread()
        ? Yup.string()
            .nullable()
            .when(SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, {
              is: (val) =>
                [RIGHTS_TYPES[1].value, RIGHTS_TYPES[2].value].indexOf(val) !==
                -1,
              then: Yup.string().nullable().required().label("This"),
            })
        : Yup.string().nullable(),
      switchDistributor: isBeatBread()
        ? Yup.string()
            .nullable()
            .when(SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME, {
              is: (val) =>
                [RIGHTS_TYPES[1].value, RIGHTS_TYPES[2].value].indexOf(val) !==
                -1,
              then: Yup.string().nullable().required().label("This"),
            })
        : Yup.string().nullable(),
    });

  const getValidateEnrollmentSchema = () =>
    Yup.object().shape(validationObject);

  const handleCaptcha = () => {
    invoke(captchaRef, "current.execute");
  };

  const calledApiFail = () => {
    setStep(FAST_FLOW_STEPS.LONGER_THAN_EXPECTED);
  };

  const handleCallAgain = () => {
    setStep(FAST_FLOW_STEPS.PENDING_AGAIN);
    handleOfferGeneration();
  };

  const goBack = () => {
    setEstimateWidgetStep(get(stepStack, stepStack.length - 1));
    setStepStack((prev) => invoke(prev, "slice", 0, -1));
  };

  const goToNextStep = (form, rightsType) => {
    setStepStack((prev) => [...prev, estimateWidgetStep]);
    switch (estimateWidgetStep) {
      case SIGNUP_STEPS.STEP1:
        if (isMastersBoth(rightsType || get(form, "values.rightsType"))) {
          setEstimateWidgetStep(SIGNUP_STEPS.STEP2);
          return true;
        }
        setEstimateWidgetStep(SIGNUP_STEPS.STEP3);
        return true;
      case SIGNUP_STEPS.STEP2:
        if (isBothType(get(form, "values.rightsType"))) {
          setEstimateWidgetStep(SIGNUP_STEPS.STEP3);
          return true;
        }
        setEstimateWidgetStep(SIGNUP_STEPS.STEP4);
        return;
      default:
        setEstimateWidgetStep((s) => s + 1);
        return;
    }
  };

  const validateFormSteps = async (form, rightsType) => {
    const errors = await invoke(form, "validateForm");
    const fieldsObject = get(themeCtx, "isPublishing")
      ? get(BB_INITIAL_FORM, estimateWidgetStep)
      : get(CC_INITIAL_FORM, estimateWidgetStep);
    const err = Object.keys(fieldsObject).filter((key) => get(errors, key));
    if (get(err, "length")) {
      Object.keys(fieldsObject).map((key) =>
        invoke(form, "setFieldTouched", key),
      );
      return false;
    }
    goToNextStep(form, rightsType);
  };

  const uploadReports = () => {
    setLoading(true);
    const apiData = {
      method: "GET",
    };
    request(
      `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}?requestId=${get(
        props,
        "match.params.requestId",
        "",
      )}&artistId=${get(otherData, "artistId", "")}&isJWT=true`,
      apiData,
    )
      .then((res) => {
        setLoading(false);
        const artistAccessToken = get(res, "data.artistAccessToken");
        if (get(res, "status") && artistAccessToken) {
          AuthTokenService.storeToken(artistAccessToken);
          if (
            get(themeCtx, "isPublishing") &&
            get(otherData, "rightsType") === RIGHTS_TYPES[0].value
          ) {
            invoke(history, "push", PUBLISHING_REPORTS);
          }
          invoke(history, "push", SEND_REPORTS);
        } else {
          setStep(FAST_FLOW_STEPS.ERROR);
        }
      })
      .catch(() => {
        setLoading(false);
        setStep(FAST_FLOW_STEPS.ERROR);
      });
  };

  const handleUploadReportPath = () => {
    const data = {
      method: "PUT",
      body: {
        choosePath: CHOOSE_PATH_FIELDS().OPTIONS[0].value,
      },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CHOOSE_PATH_API}`;
    request(requestUrl, data)
      .then((res) => {
        if (!res.status) {
          setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
          return false;
        }
        segment.track.decision("Upload Reports");
        const redirectPath =
          get(otherData, "rightsType") === RIGHTS_TYPES[0].value
            ? PUBLISHING_REPORTS
            : SEND_REPORTS;
        invoke(history, "push", redirectPath);
        return true;
      })
      .catch((err) => {
        setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
        return false;
      });
  };

  const handleGetEstimatePath = () => {
    const data = {
      method: "PUT",
      body: {
        choosePath: CHOOSE_PATH_FIELDS().OPTIONS[1].value,
      },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CHOOSE_PATH_API}`;
    request(requestUrl, data)
      .then((res) => {
        if (!res.status) {
          setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
          return false;
        }
        segment.track.decision("Get Estimates");
        invoke(history, "push", FUNDING);
        return true;
      })
      .catch((err) => {
        setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
        return false;
      });
  };

  const handleRequestCallPath = () => {
    const data = {
      method: "PUT",
      body: {
        choosePath: CHOOSE_PATH_FIELDS().OPTIONS[2].value,
      },
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CHOOSE_PATH_API}`;
    request(requestUrl, data)
      .then((res) => {
        if (!res.status) {
          setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
          return false;
        }
        segment.track.decision("Request Call");
        setStep(FAST_FLOW_STEPS.REQUEST_CALL);
        return true;
      })
      .catch((err) => {
        setStep(FAST_FLOW_STEPS.SERVICE_DOWN);
        return false;
      });
  };

  const handleStreamingIncome = (res) => {
    if (get(res, "status")) {
      if (get(res, "data.streamingIncomeCounter") > 2) {
        setStep(FAST_FLOW_STEPS.INCOME_NOT_VERIFIED);
        setStreamingIncomeCounter(get(res, "data.streamingIncomeCounter"));
      } else {
        setStep(FAST_FLOW_STEPS.PENDING);
        handleOfferGeneration();
      }
    } else {
      setLoading(false);
      setStep(FAST_FLOW_STEPS.ERROR);
    }
  };

  const handleRequestFailedWidgetClick = () => {
    setLoading(false);
    setOtherData((p) => ({
      ...p,
      streamingIncomeValue: 0,
      artistId: "",
      recentRevenue: 0,
    }));
    setStep("");
  };

  const renderLabel = (labelText, infoText) => (
    <div className={styles.flex}>
      <div className={styles.labelText}>{labelText}</div>
      {infoText && (
        <>
          <i
            className={styles.infoIcon}
            data-tip
            data-for={labelText}
            data-place="top"
          ></i>
          <ReactTooltip
            delayHide={100}
            class={styles.tooltip}
            effect="solid"
            place="top"
            id={labelText}
          >
            {infoText}
          </ReactTooltip>
        </>
      )}
    </div>
  );

  const getTermsUrl = () =>
    getPartnerName() === "beatBread"
      ? BEATBREAD_TERMS_OF_SERVICE
      : CHORDCASH_TERMS_OF_SERVICE;

  const formBtnRender = (formValues) => (
    <div className={styles.btnContainer}>
      <button
        className={styles.btnPrimary}
        disabled={btnLoader || btnLoader1 || loading}
        type="button"
        name="button"
        data-testid="step-one"
        onClick={() => validateFormSteps(formValues)}
      >
        <span>CONTINUE</span>
        {(btnLoader || btnLoader1) && (
          <span className={styles.spin}>
            <Loader />
          </span>
        )}
      </button>
    </div>
  );

  const renderStepIndicator = (formValues) => {
    if (!get(themeCtx, "isPublishing")) return false;
    if (!get(formValues, `values.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`))
      return false;
    if (
      get(formValues, `values.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`) ===
      RIGHTS_TYPES[2].value
    ) {
      return (
        <div className={styles.stepIndicator}>
          {invoke(Object.values(SIGNUP_STEPS).slice(1), "map", (s) => (
            <span className={estimateWidgetStep === s ? styles.active : ""} />
          ))}
        </div>
      );
    }
    return (
      <div className={styles.stepIndicator}>
        <span
          className={
            [SIGNUP_STEPS.STEP2, SIGNUP_STEPS.STEP3].indexOf(
              estimateWidgetStep,
            ) !== -1
              ? styles.active
              : ""
          }
        />
        <span
          className={
            estimateWidgetStep === SIGNUP_STEPS.STEP4 ? styles.active : ""
          }
        />
      </div>
    );
  };

  const renderFormSteps = (formValues) => {
    switch (estimateWidgetStep) {
      case SIGNUP_STEPS.STEP1:
        return (
          <>
            <div className={`${styles.firstDiv} ${styles.estimatesWidget}`}>
              <div className={`form-group  mb-3`}>
                <FormField
                  as="radio"
                  name={SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}
                  label={SIGNUP_FORM_FIELDS.RIGHTS_TYPE.LABEL}
                  radioValueList={SIGNUP_FORM_FIELDS.RIGHTS_TYPE.OPTIONS}
                  containerClass={styles.radioContainer}
                  data-testid="publishing-radio"
                  key={SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}
                  labelClassName={"m-auto"}
                  onClick={async (e) => {
                    invoke(
                      formValues,
                      "setFieldValue",
                      SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME,
                      get(e, "target.value"),
                    );
                    const rightsType = get(e, "target.value");
                    await Promise.resolve();
                    validateFormSteps(formValues, rightsType);
                  }}
                />
              </div>
            </div>
          </>
        );
      case SIGNUP_STEPS.STEP2:
        return (
          <>
            <div className={`${styles.firstDiv} ${styles.estimatesWidget}`}>
              <div className={`form-group  mb-3`}>
                <FormField
                  name={ESTIMATES_FIELD_NAMES.AMOUNT.NAME}
                  as="numeric"
                  placeholder={ESTIMATES_FIELD_NAMES.AMOUNT.PLACEHOLDER}
                  label={renderLabel(
                    ESTIMATES_FIELD_NAMES.AMOUNT.LABEL,
                    ESTIMATES_FIELD_NAMES.AMOUNT.TOOLTIP,
                  )}
                  disabled={btnLoader}
                  className={styles.inputContainer}
                  mainDiv={styles.mainInputContainer}
                  prefix="$"
                  suffix="  USD"
                  autoComplete="off"
                  key={ESTIMATES_FIELD_NAMES.AMOUNT.NAME}
                />
              </div>
              {isBeatBread() && (
                <>
                  <div className={`form-group  mb-3`}>
                    <FormField
                      name={ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME}
                      as="select"
                      placeholder={
                        ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.PLACEHOLDER
                      }
                      label={renderLabel(
                        ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.LABEL,
                      )}
                      data-testid="major-distributor"
                      onBlur={() => {
                        invoke(
                          formValues,
                          "setFieldTouched",
                          ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME,
                          true,
                        );
                      }}
                      components={{ IndicatorSeparator: null }}
                      mainDiv={styles.mainInputContainer}
                      options={distributorList}
                      key={ESTIMATES_FIELD_NAMES.MAJOR_DISTRIBUTOR.NAME}
                    />
                  </div>
                  <div className={`form-group  mb-3`}>
                    <FormField
                      name={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME}
                      as="select"
                      placeholder={
                        ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.PLACEHOLDER
                      }
                      label={renderLabel(
                        ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.LABEL,
                      )}
                      data-testid="switch-distributor"
                      onBlur={() => {
                        invoke(
                          formValues,
                          "setFieldTouched",
                          ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME,
                          true,
                        );
                      }}
                      components={{ IndicatorSeparator: null }}
                      mainDiv={styles.mainInputContainer}
                      options={SWITCH_DIST_OPTIONS}
                      renderHelperLabel={
                        <p className={styles.helperLabel}>
                          * Switching distributors is not required to receive
                          funding. Artists that are able and willing to switch
                          distributors may receive more choices and better
                          terms.
                        </p>
                      }
                      key={ESTIMATES_FIELD_NAMES.SWITCH_DISTRIBUTOR.NAME}
                    />
                  </div>
                </>
              )}
            </div>
            {formBtnRender(formValues)}
            {renderStepIndicator(formValues)}
          </>
        );
      case SIGNUP_STEPS.STEP3:
        return (
          <>
            <div className={`${styles.firstDiv} ${styles.estimatesWidget}`}>
              <div className={`form-group  mb-3`}>
                <FormField
                  name={PUBLISHING_FIELDS.PRO.NAME}
                  as="select"
                  placeholder={PUBLISHING_FIELDS.PRO.PLACEHOLDER}
                  label={PUBLISHING_FIELDS.PRO.LABEL}
                  data-testid="publishing-options"
                  onBlur={() => {
                    invoke(
                      formValues,
                      "setFieldTouched",
                      PUBLISHING_FIELDS.PRO.NAME,
                      true,
                    );
                  }}
                  components={{ IndicatorSeparator: null }}
                  mainDiv={styles.mainInputContainer}
                  options={proOptions}
                  key={PUBLISHING_FIELDS.PRO.NAME}
                />
              </div>
              {get(formValues, `values.${PUBLISHING_FIELDS.PRO.NAME}`) !==
                POR_DEFAULT_OPTION.value && (
                <div className={`form-group  mb-3`}>
                  <FormField
                    name={PUBLISHING_FIELDS.PUBLISHER.NAME}
                    as="select"
                    placeholder={PUBLISHING_FIELDS.PUBLISHER.PLACEHOLDER}
                    label={PUBLISHING_FIELDS.PUBLISHER.LABEL}
                    data-testid="artist-publisher-options"
                    onBlur={() => {
                      invoke(
                        formValues,
                        "setFieldTouched",
                        PUBLISHING_FIELDS.PUBLISHER.NAME,
                        true,
                      );
                    }}
                    components={{ IndicatorSeparator: null }}
                    mainDiv={styles.mainInputContainer}
                    options={publisherOptions}
                    key={PUBLISHING_FIELDS.PUBLISHER.NAME}
                  />
                </div>
              )}
              {(get(
                formValues,
                `values.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`,
              ) === RIGHTS_TYPES[0].value ||
                (get(formValues, `values.${PUBLISHING_FIELDS.PRO.NAME}`) !==
                  POR_DEFAULT_OPTION.value &&
                  get(
                    formValues,
                    `values.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`,
                  ) === RIGHTS_TYPES[2].value)) && (
                <div className={`form-group  mb-3`}>
                  {get(
                    formValues,
                    `values.${SIGNUP_FORM_FIELDS.RIGHTS_TYPE.NAME}`,
                  ) === RIGHTS_TYPES[0].value &&
                  get(formValues, `values.${PUBLISHING_FIELDS.PRO.NAME}`) ===
                    POR_DEFAULT_OPTION.value ? (
                    <FormField
                      name={PUBLISHING_FIELDS.INCOME.NAME}
                      as="numeric"
                      placeholder={ESTIMATES_FIELD_NAMES.AMOUNT.PLACEHOLDER}
                      label={renderLabel(
                        ESTIMATES_FIELD_NAMES.AMOUNT.LABEL,
                        ESTIMATES_FIELD_NAMES.AMOUNT.TOOLTIP,
                      )}
                      disabled={btnLoader}
                      className={styles.inputContainer}
                      mainDiv={styles.mainInputContainer}
                      prefix="$"
                      suffix="  USD"
                      autoComplete="off"
                      key={PUBLISHING_FIELDS.INCOME.NAME}
                    />
                  ) : (
                    <FormField
                      name={PUBLISHING_FIELDS.INCOME.NAME}
                      as="numeric"
                      placeholder={PUBLISHING_FIELDS.INCOME.PLACEHOLDER}
                      label={PUBLISHING_FIELDS.INCOME.LABEL}
                      disabled={get(formValues, "isSubmitting")}
                      className={styles.inputContainer}
                      mainDiv={styles.mainInputContainer}
                      prefix="$"
                      suffix="  USD"
                      autoComplete="off"
                      key={PUBLISHING_FIELDS.INCOME.NAME}
                    />
                  )}
                </div>
              )}
            </div>
            {formBtnRender(formValues)}
            {renderStepIndicator(formValues)}
          </>
        );
      case SIGNUP_STEPS.STEP4:
      default:
        return (
          <>
            <div className={`${styles.firstDiv} ${styles.estimatesWidget}`}>
              <div className={`form-group  mb-3`}>
                <FormField
                  name={ESTIMATES_FIELD_NAMES.CONTACT_NAME.NAME}
                  type="text"
                  placeholder={ESTIMATES_FIELD_NAMES.CONTACT_NAME.PLACEHOLDER}
                  label={renderLabel(ESTIMATES_FIELD_NAMES.CONTACT_NAME.LABEL)}
                  disabled={btnLoader}
                  className={styles.inputContainer}
                  mainDiv={styles.mainInputContainer}
                  key={ESTIMATES_FIELD_NAMES.CONTACT_NAME.NAME}
                />
              </div>
              <div className={`form-group  mb-3`}>
                <FormField
                  name={ESTIMATES_FIELD_NAMES.RELATIONSHIP_WITH_ARTIST.NAME}
                  as="select"
                  placeholder={
                    ESTIMATES_FIELD_NAMES.RELATIONSHIP_WITH_ARTIST.PLACEHOLDER
                  }
                  label={renderLabel(
                    ESTIMATES_FIELD_NAMES.RELATIONSHIP_WITH_ARTIST.LABEL,
                  )}
                  data-testid="artist-relationship"
                  onBlur={() => {
                    invoke(
                      formValues,
                      "setFieldTouched",
                      ESTIMATES_FIELD_NAMES.RELATIONSHIP_WITH_ARTIST.NAME,
                      true,
                    );
                  }}
                  components={{ IndicatorSeparator: null }}
                  mainDiv={styles.mainInputContainer}
                  options={RELATIONSHIP_OPTIONS}
                  key={ESTIMATES_FIELD_NAMES.RELATIONSHIP_WITH_ARTIST.NAME}
                />
              </div>
              <div className={`form-group  mb-3 ${styles.emailField}`}>
                <FormField
                  name={ESTIMATES_FIELD_NAMES.EMAIL.NAME}
                  placeholder={ESTIMATES_FIELD_NAMES.EMAIL.PLACEHOLDER}
                  label={renderLabel(ESTIMATES_FIELD_NAMES.EMAIL.LABEL)}
                  disabled={btnLoader}
                  className={styles.inputContainer}
                  mainDiv={styles.mainInputContainer}
                  onBlur={(e) => {
                    handleEmailCheck(formValues);
                    invoke(
                      formValues,
                      "setFieldTouched",
                      ESTIMATES_FIELD_NAMES.EMAIL.NAME,
                      true,
                    );
                  }}
                  key={ESTIMATES_FIELD_NAMES.EMAIL.NAME}
                />
                {emailLoader && (
                  <span className={styles.spin}>
                    <Loader />
                  </span>
                )}
              </div>
              <div className={`form-group  mb-3  ${styles.phoneInput}`}>
                <FormField
                  name="phone"
                  as="phone"
                  label={ESTIMATES_FIELD_NAMES.PHONE_NUMBER.LABEL}
                  placeholder={ESTIMATES_FIELD_NAMES.PHONE_NUMBER.PLACEHOLDER}
                  disabled={btnLoader}
                  className={`${styles.inputContainer}`}
                  mainDiv={styles.mainInputContainer}
                  key={ESTIMATES_FIELD_NAMES.PHONE_NUMBER.NAME}
                />
              </div>
              {!isExistingUser && (
                <div className={`form-group  mb-3`}>
                  <FormField
                    name={ESTIMATES_FIELD_NAMES.PASSWORD.NAME}
                    as="password"
                    placeholder={ESTIMATES_FIELD_NAMES.PASSWORD.PLACEHOLDER}
                    label={renderLabel(ESTIMATES_FIELD_NAMES.PASSWORD.LABEL)}
                    disabled={btnLoader}
                    className={styles.inputContainer}
                    mainDiv={styles.mainInputContainer}
                    key={ESTIMATES_FIELD_NAMES.PASSWORD.NAME}
                  />
                </div>
              )}
              <div className={`${styles.checkboxClass} ${styles.checkbox}`}>
                <FormField
                  name="checkAgreement"
                  type="checkbox"
                  className={styles.checkboxDiv}
                  id="checkAgreement"
                  disabled={btnLoader}
                  mainDiv={styles.mainInputContainer}
                  data-testid="privacy-check"
                />
                <label htmlFor="checkAgreement">
                  I agree to {getPartnerName()}’s{" "}
                  <a
                    className={styles.link}
                    href={getTermsUrl()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and acknowledge {getPartnerName()}’s{" "}
                  <Link target="_blank" className={styles.link} to={"/privacy"}>
                    Privacy Notice
                  </Link>
                  . I consent to receive {getPartnerName()} messages via SMS.
                  Standard call, message, or data rates may apply.
                </label>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <button
                className={styles.btnPrimary}
                disabled={
                  btnLoader ||
                  btnLoader1 ||
                  loading ||
                  !get(formValues, "values.checkAgreement")
                }
                type="submit"
                name="submit"
              >
                <span>CONTINUE TO ESTIMATES</span>
                {(btnLoader || btnLoader1) && (
                  <span className={styles.spin}>
                    <Loader />
                  </span>
                )}
              </button>
            </div>
            {renderStepIndicator(formValues)}
          </>
        );
    }
  };

  const renderEstimatesWidget = (formValues) => (
    <>
      <div className={styles.recaptchaMainDiv}>
        <Recaptcha
          theme="light"
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          size="invisible"
          ref={captchaRef}
          onChange={(e) => {
            handleSubmitEstimates(formValues, e);
          }}
          className={styles.captchaDiv}
          data-testid="captcha"
        />
      </div>
      {renderFormSteps(formValues)}
    </>
  );

  const estimatesWidget = () => (
    <div className={styles.formContainer}>
      <div className={styles.formHeading}>
        <p>You have selected</p>
        <div className={styles.artistInfo}>
          <Image
            imageType={IMAGE_TYPE.PROFILE}
            src={get(artistData, "images.0.url")}
            alt="profile"
          />
          <h3>{get(artistData, "name", "")}</h3>
        </div>
      </div>
      <Formik
        initialValues={ARTIST_ENROLLMENT_INITIAL_DATA}
        validationSchema={
          get(themeCtx, "isPublishing")
            ? getValidationSchemaBB()
            : getValidateEnrollmentSchema()
        }
        onSubmit={handleCaptcha}
        enableReinitialize
        className={styles.signupForm}
      >
        {({
          values,
          resetForm,
          validateForm,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          handleSubmit,
          errors,
        }) => (
          <Form>
            {renderEstimatesWidget({
              values,
              resetForm,
              validateForm,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              handleSubmit,
              errors,
            })}
          </Form>
        )}
      </Formik>
    </div>
  );

  const renderSteps = () => {
    switch (step) {
      case FAST_FLOW_STEPS.PENDING:
        return <HoldTightWidget calledApiFail={calledApiFail} />;
      case FAST_FLOW_STEPS.LONGER_THAN_EXPECTED:
        return (
          <LongerThanExpected
            title={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.TITLE}
            subtitle={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.SUBTITLE}
            linkText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.LINK_TEXT}
            firstBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.FIRST_BTN}
            secondBtnText={
              FAST_FLOW_DATA.LONGER_THAN_EXPECTED_BEFORE.SECOND_BTN
            }
            isFinalExpected={false}
            onClick={handleCallAgain}
            uploadReports={uploadReports}
          />
        );
      case FAST_FLOW_STEPS.PENDING_AGAIN:
        return <FinalHoldTightWidget />;
      case FAST_FLOW_STEPS.LONGER_THAN_EXPECTED_FINAL:
        return (
          <LongerThanExpected
            title={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.TITLE}
            subtitle={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.SUBTITLE}
            linkText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.LINK_TEXT}
            firstBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.FIRST_BTN}
            secondBtnText={FAST_FLOW_DATA.LONGER_THAN_EXPECTED.SECOND_BTN}
            isFinalExpected={true}
            uploadReports={uploadReports}
          />
        );
      case FAST_FLOW_STEPS.INCOME_VERIFIED:
        return (
          <IncomeVerified
            monthlyIncome={get(otherData, "streamingIncomeValue")}
            requestId={get(props, "match.params.requestId", "")}
            artistId={get(otherData, "artistId", "")}
            streamingIncomeCounter={streamingIncomeCounter}
            showstreamingInput={false}
            uploadReports={uploadReports}
            handleStreamingIncome={handleStreamingIncome}
            rightsType={get(otherData, "rightsType")}
            PRO={get(otherData, "PRO")}
            recentRevenue={get(otherData, "recentRevenue")}
            isPublishing={get(themeCtx, "isPublishing")}
            chordCashContent={
              <div className={styles.formHeading}>
                <p>You have selected</p>
                <div className={styles.artistInfo}>
                  <Image
                    imageType={IMAGE_TYPE.PROFILE}
                    src={get(artistData, "images.0.url")}
                    alt="profile"
                  />
                  <h3>{get(artistData, "name", "")}</h3>
                </div>
              </div>
            }
          />
        );
      case FAST_FLOW_STEPS.INCOME_NOT_VERIFIED:
        return <IncomeNotVerified uploadReports={uploadReports} />;
      case FAST_FLOW_STEPS.FAILED:
      case FAST_FLOW_STEPS.SERVICE_DOWN:
      case FAST_FLOW_STEPS.ERROR:
        return <RequestFailedWidget onClick={handleRequestFailedWidgetClick} />;
      case FAST_FLOW_STEPS["2SMALL"]:
      case FAST_FLOW_STEPS["2NEW"]:
        return <TooSmallWidget artistName={get(artistData, "name", "")} />;
      case FAST_FLOW_STEPS["2BIG"]:
        return <TooBigWidget artistName={get(artistData, "name", "")} />;
      case FAST_FLOW_STEPS.CHOOSE_PATH:
        return (
          <ChoosePath
            handleUploadReportPath={handleUploadReportPath}
            handleGetEstimatePath={handleGetEstimatePath}
            rightsType={get(otherData, "rightsType")}
            handleRequestCallPath={handleRequestCallPath}
            showAllPath={
              get(otherData, "streamingIncomeValue") >
                DISPLAY_ALL_PATH_CONDITION ||
              get(otherData, "recentRevenue") > DISPLAY_ALL_PATH_CONDITION
            }
          />
        );
      case FAST_FLOW_STEPS.REQUEST_CALL:
        return (
          <RequestCall
            handleUploadReportPath={handleUploadReportPath}
            handleGetEstimatePath={handleGetEstimatePath}
          />
        );
      default:
        return estimatesWidget();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.logoContainer}>
            <Image
              src={get(themeCtx, "appLogo")}
              alt="logo"
              imageType={IMAGE_TYPE.LOGO}
              className={styles.instanceLogo}
            />
            {!isBeatBread() && <PoweredByCC />}
          </div>
        </div>
        <div className={`${styles.subContainer}`}>
          <p className={styles.subHeading}>
            Confirm a few details and we’ll generate estimates against your
            streaming catalog in a few seconds.
          </p>
          {renderSteps()}
          {!step && stepStack.length ? (
            <button className={styles.returnLink} onClick={goBack}>
              <SvgIcons icon={ARROW_BACK} /> Go Back
            </button>
          ) : (
            <a
              className={styles.returnLink}
              href={get(themeCtx, "whitelistUrl.0", "")}
            >
              <SvgIcons icon={ARROW_BACK} /> Return to search
            </a>
          )}
        </div>
        {loading && <Loader />}
      </div>
      <div
        className={`${styles.backgroundImage} ${
          isBeatBread() ? styles.bB : ""
        }`}
      />
    </>
  );
};

export default SignupFastFlow;
